<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <img src="@/assets/images/icons8-people-96.png" class="h-20 ml-2 inline-block" />
        اطلاعات گروه
      </h2>
      <h2 class="text-xl font-bold">
        <Icon name="Group" />
        {{ group.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ group.gid }}</div>
    </template>
    <template #default>
      <div class="grid sm:grid-cols-2 gap-3">
        <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md">
          <div class="text-lg font-bold">
            <img
              src="@/assets/images/icons8-membership-card-96.png"
              class="inline-block h-20"
            />
            خرید اشتراک در این گروه
          </div>
          <p class="mt-5">
            میتوانید اشتراک این گروه را خرید کنید و از ویژگی های آن بهرمند شوید
          </p>
          <div class="grid sm:grid-cols-3">
            <Button
              :to="{
                name: 'GroupUserAccounts',
                params: { id: $route.params.id, groupId: $route.params.groupId },
              }"
              class="mt-3 inline-flex text-lg"
              color="cyan"
            >
              خرید اشتراک
            </Button>
          </div>
        </div>
        <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md">
          <div class="text-lg font-bold">
            <img
              src="@/assets/images/1054990_rocket_spacecraft_spaceship_icon.png"
              class="inline-block h-20"
            />
            سفارش تبلیغات در این گروه
          </div>
          <p class="mt-5">میتوانید در این گروه به طور خودکار تبلیغات سفارش دهید</p>
          <div class="grid sm:grid-cols-3">
            <Button
              :to="{
                name: 'TargetOrder',
                params: { id: $route.params.id },
                query: { groupId: $route.params.groupId },
              }"
              class="mt-3 inline-flex text-lg"
              color="cyan"
            >
              سفارش تبلیغ
            </Button>
          </div>
        </div>
      </div>
      <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mt-5">
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-info-96.png" class="inline-block h-20" />
          توضیحات گروه
        </div>
        <p class="mt-5">
          {{ group.info?.bio }}
        </p>
        <hr class="border-blueGray-200 my-3" />
        <p>
          {{ group.info?.info }}
        </p>
      </div>
      <div
        class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mt-5"
        v-if="group?.links?.[0]?.invite_link"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-link-96.png" class="inline-block h-20" />
          لینک گروه
        </div>
        <p v-if="group?.links?.[0]?.name == 'vip_link'">
          این گروه VIP است ابتدا باید اشتراک خرید کرده تا بتوانید وارد گروه شود.
        </p>
        <div class="sm:w-1/3 mx-auto mt-5">
          <Button :href="group?.links?.[0]?.invite_link" target="_blank">
            <template v-if="group?.links?.[0]?.name == 'vip_link'">
              درخواست عضویت در گروه
            </template>
            <template v-else>برای رفتن به گروه کلیک کنید</template>
          </Button>
        </div>
      </div>
      <div class="grid 2xl:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-3">
        <div
          v-for="admin in admins"
          :key="admin.user.id"
          class="
            p-3
            bg-blueGray-100
            border border-blueGray-200
            rounded-md
            mt-3
            text-center
          "
        >
          <img src="@/assets/images/icons8-administrator-male-96.png" class="mx-auto" />
          <h2 class="mt-3">{{ admin.user.first_name }} {{ admin.user.last_name }}</h2>
          <div class="mt-1">{{ list_admins[admin.status] }}</div>
          <Button
            v-if="admin.user.username"
            :href="'https://t.me/' + admin.user.username"
            target="_blank"
            class="mt-3 sm:text-lg"
            color="cyan"
          >
            پیام در تلگرام
          </Button>
          <Button v-else class="mt-3 sm:text-lg" color="cyan" :disabled="true">
            پیام در تلگرام
          </Button>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import Button from "@/components/Button.vue";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
  },
  data() {
    return {
      firstLoading: true,
      group: {},
      admins: [],
      list_admins: {
        administrator: "ادمین",
        creator: "سازنده",
      },
      // L_: _,
    };
  },
  mounted() {
    this.getData();
    this.getAdmins();
  },
  methods: {
    getData() {
      let $this = this;
      // console.log($this.$store.state.user.id);
      $this.$axios
        .get("/api/user-groups/" + $this.$route.params.groupId, {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.group = response.data.group;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .finally(function () {
          // always executed
          $this.firstLoading = false;
        });
    },
    getAdmins() {
      let $this = this;
      // console.log($this.$store.state.user.id);
      $this.$axios
        .get("/api/user-groups/" + $this.$route.params.groupId + "/admins", {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.admins = response.data.admins;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .finally(function () {
          // always executed
          // $this.firstLoading = false;
        });
    },
  },
};
</script>

<style></style>
